import { Select, SelectProps } from 'antd';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Rounded } from '../theme/Rounded';
import { Flex } from './Flex';

const StyledSelectContainer = styled(Flex)`
  flex-direction: column;

  .ant-select-selector {
    border: 2px solid ${({ theme }) => theme.black} !important;
    border-radius: ${Rounded.md} !important;
    font-size: 1.5rem;
  }

  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.black};
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }
`;

interface DropDownProps extends SelectProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  title?: string;
  containerStyle?: CSSProperties;
  onChange: (v: string) => void;
}

export const DropDown: React.FC<DropDownProps> = ({
  onChange,
  options,
  value,
  width,
  height,
  title,
  borderRadius,
  containerStyle,
  ...props
}) => {
  return (
    <StyledSelectContainer gap="s" style={containerStyle}>
      {title ? <label>{title}</label> : null}
      <Select
        onChange={onChange}
        value={value}
        options={options}
        {...props}
        style={{ height: height || '2.3rem' }}
      />
    </StyledSelectContainer>
  );
};
