import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import omitBy from 'lodash/omitBy';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { getMediaUrl } from '../../../utils/Media';
import { truncateString } from '../../../utils/String';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.l};
  flex: 1;
  position: sticky;
  height: fit-content;
  width: 350px;
  top: ${Spacing.m};
  margin-inline: ${Spacing.s};
`;

const PhoneWrapper = styled(Flex)`
  border: 4px solid ${({ theme }) => theme.black};
  border-radius: ${Rounded.xxl};
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  background-color: ${({ theme }) => theme.white};
  overflow: hidden;
  height: 30rem;
  width: 100%;
`;

const StyledHr = styled.hr`
  background-color: ${({ theme }) => theme.black};
  width: 100%;
  height: 2px;
  opacity: 1;
  margin-top: ${Spacing.xxl};
`;

const EmptyPhotoFlex = styled(Flex)`
  width: 4rem;
  height: 4rem;
  border-radius: ${Rounded.circle};
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    width: 50%;
    height: 50%;
  }
`;

const SavePreviewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  border-radius: ${Rounded.pill};
  text-decoration: none;
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.white};
  font-size: 1.3rem;
  padding: 0.6rem;
  width: 13rem;
  height: 2.4rem;

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

interface AtiraLinkPreviewPhoneProps {
  loading: boolean;
  setLoading: ATVoidFunction<boolean>;
}

export const AtiraLinkPreviewPhone: React.FC<AtiraLinkPreviewPhoneProps> = ({
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const editMode = Boolean(atiraLink?._id);

  const [title, linkName, image, bio] = watch([
    'title',
    'linkName',
    'image',
    'bio',
  ]);

  const onSave = async () => {
    try {
      setLoading(true);

      if (editMode) {
        await edit();
      } else {
        await create();
      }

      await dispatch(AtiraLinkActions.getUserAtiraLink({ userId })).unwrap();

      AtiraToast.success(
        t(editMode ? 'atira_link.edit.success' : 'atira_link.create.success'),
      );

      if (!editMode) {
        navigate('/dashboard');
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const create = async () => {
    const dto = getValues();

    const customLinks = dto.customLinks.filter((x: string) => x);

    return dispatch(
      AtiraLinkActions.createAtiraLink({
        ...(deepCleanDto(dto) as CreateAtiraLinkDto),
        customLinks,
      }),
    ).unwrap();
  };

  const edit = async () => {
    const dto = getValues();

    const customLinks = dto.customLinks.filter((x: string) => x);

    return dispatch(
      AtiraLinkActions.editAtiraLink({
        atiraLinkId: atiraLink!._id,
        ...dto,
        customLinks,
      } as EditAtiraLinkDto),
    ).unwrap();
  };

  const deepCleanDto = (obj: any) => {
    const cleanDto = (obj: any) => {
      return omitBy(
        obj,
        (value) =>
          isNil(value) ||
          (Array.isArray(value) &&
            value.filter((v) => !isNil(v)).length === 0) ||
          !value,
      );
    };

    const cleanedObj = cleanDto(obj);
    Object.keys(cleanedObj).forEach((key) => {
      if (Array.isArray(cleanedObj[key])) {
        cleanedObj[key] = cleanedObj[key].filter((v) => !isNil(v));
      }
    });
    return cleanedObj;
  };

  return (
    <Container>
      <PhoneWrapper>
        <StyledHr />

        <Flex flexDirection="column" gap="s" alignItems="center" padding="l">
          <EmptyPhotoFlex>
            {image ? (
              <AtiraImage
                src={
                  isString(image)
                    ? getMediaUrl(image)
                    : URL.createObjectURL(image)
                }
                height="100%"
              />
            ) : (
              <AtiraIcon icon={faCamera} />
            )}
          </EmptyPhotoFlex>

          <Text
            color="darkerSub"
            fontSize="l"
            fontWeight={'bold'}
            wordBreak="break-word"
            align="center"
          >
            {title
              ? truncateString(title, 25)
              : t('atira_link.create.content.title.placeholder')}
          </Text>

          <Flex maxWidth={'60%'}>
            <Text align="center" wordBreak="break-word">
              {bio
                ? truncateString(bio, 60)
                : t('atira_link.create.content.text.placeholder')}
            </Text>
          </Flex>
        </Flex>
      </PhoneWrapper>

      <SavePreviewButton
        disabled={title?.length < 3 || linkName?.length < 3}
        onClick={onSave}
        loading={loading}
      >
        <AtiraIcon icon={editMode ? faSpinner : faUpRightFromSquare} />

        {editMode ? t('common.update') : t('atira_link.create.save_preview')}
      </SavePreviewButton>
    </Container>
  );
};
