import { t } from 'i18next';
import { createBrowserRouter } from 'react-router-dom';

import { Features } from '../../Features';
import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { Layout } from '../Layout';
import { PrivateRouter } from './Private.router';
import { PublicRouter } from './Public.router';

const router = createBrowserRouter([
  ...PublicRouter,
  {
    element: <Layout />,
    errorElement: <div>{t('common.error.back_home')}</div>,
    children: [...PrivateRouter],
  },
  {
    path: '/',
    element: (
      <AtiraRouteNotLoggedIn component={<Features />} redirect="/dashboard" />
    ),
  },
  { path: '*', element: <AtiraRoute redirect="/" /> },
]);

export default router;
