// ThemeContext.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { SecondTheme, Theme, ThemeType } from './theme/Theme';

interface ThemeContextType {
  toggleTheme: VoidFunction;
  theme: ThemeType;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const getInitialTheme = (): ThemeType => {
    const savedTheme = window.localStorage.getItem('theme');
    if (savedTheme === Themes.DARK) {
      return Theme;
    } else if (savedTheme === Themes.LIGHT) {
      return SecondTheme;
    }
    return Theme; // Default theme
  };

  const [theme, setTheme] = useState<ThemeType>(() => getInitialTheme());

  const toggleTheme = () => {
    setTheme((prevTheme: ThemeType) =>
      prevTheme === Theme ? SecondTheme : Theme,
    );
    window.localStorage.setItem(
      'theme',
      theme === Theme ? Themes.LIGHT : Themes.DARK,
    );
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
