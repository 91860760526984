import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import i18n from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';

const StyledButton = styled(Button)`
  font-size: 1.3rem;
  border-radius: 0;
  height: 4rem;
  padding: 0;
  width: 8rem;
`;

export const URLShortner = () => {
  const { t } = useTranslation();

  const { theme } = useTheme();
  const location = useLocation();
  const { nerveURL } = location.state || {};

  const [url, setUrl] = useState('');
  const [currentShortURL, setCurrentShortURL] = useState('');
  const [currentOriginalURL, setCurrentOriginalURL] = useState('');
  const [loading, setLoading] = useState(false);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dispatch = useAppDispatch();
  const [isUrlValid, setIsUrlValid] = useState(true);

  const navigate = useNavigate();

  const websiteLanguage = i18n.language;

  const fetchShortURL = async () => {
    try {
      setLoading(true);

      if (!url) {
        setIsUrlValid(false);
        AtiraToast.error(t('shorten_url.create.error.enter_url'));
        return;
      }

      setIsUrlValid(true);

      const response = await dispatch(
        shortenUrlActions.getShortURL({
          userId: loggedInUserId!,
          originalURL: url,
        }),
      ).unwrap();

      setCurrentOriginalURL(response.url.originalURL);
      setCurrentShortURL(response.url.shortURL!);

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();
      AtiraToast.success(t('shorten_url.create.success'));
      setCurrentShortURL(response.url.shortURL!);
      setUrl('');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (nerveURL) {
      setUrl(nerveURL);
    }
  }, [nerveURL]);
  return (
    <Flex flexDirection="column">
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="xxl"
      >
        <Text align="center" fontSize="xl">
          {t('shorten_url.title')}
        </Text>
        <Text align="center" fontSize="m" color="gray">
          {t('shorten_url.description')}
        </Text>

        <Flex alignItems="center" gap="l" width={'32rem'}>
          <Input
            value={url}
            placeholder={t('shorten_url.input_placeholder')}
            width="24rem"
            height="4rem"
            border={isUrlValid ? '2px solid black' : '2px solid red'}
            padding=" 1rem"
            style={{ fontSize: '1.2rem' }}
            onChange={(e) => {
              setUrl(e.currentTarget.value);
              setIsUrlValid(true);
            }}
          />
          <StyledButton onClick={fetchShortURL} loading={loading}>
            {t('shorten_url.button_text')}
          </StyledButton>
        </Flex>

        {currentShortURL ? (
          <Flex
            width={'32rem'}
            border={`2px solid ${theme.black}`}
            gap="l"
            padding="m"
          >
            <Flex
              alignItems="center"
              flexDirection="column"
              width={'50%'}
              gap="m"
            >
              <Text fontSize="l">{t('shorten_url.original_url')}</Text>
              <div
                style={{
                  overflowWrap: 'break-word',
                  width: '100%',
                }}
              >
                {currentOriginalURL}
              </div>
            </Flex>

            <Flex
              gap="m"
              width={'50%'}
              flexDirection="column"
              alignItems="center"
              borderLeft={`2px solid ${theme.black}`}
              paddingLeft="s"
            >
              <Text fontSize="l">{t('shorten_url.response.new_url')}</Text>

              <Flex
                width={'100%'}
                gap="m"
                flexWrap="wrap"
                justifyContent="center"
              >
                <div
                  style={{
                    width: '70%',
                    overflowWrap: 'break-word',
                  }}
                >
                  {currentShortURL}
                </div>
                <CopyButton copy={currentShortURL} />
              </Flex>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
