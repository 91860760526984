import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowUpFromBracket';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { userActions } from '../redux/user/user.slice';
import { Rounded } from '../theme/Rounded';
import { truncateString } from '../utils/String';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { Text } from './Text';

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  margin: 0;
  padding: 0;
`;

export const UserHeader: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const onLogout = () => {
    dispatch(userActions.logout());
    navigate('/login', { replace: true });
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: '2',
          label: (
            <Text fontSize="m" color="black">
              {user?.email ? (
                <Tooltip title={user.email}>
                  {truncateString(user.email)}
                </Tooltip>
              ) : (
                t('common.user_email')
              )}
            </Text>
          ),
        },
      ],
    },
    {
      key: '3',
      label: <hr />,
    },
    {
      key: '6',
      label: (
        <Button
          onClick={onLogout}
          fontSize="1rem"
          backgroundColor="transparent"
          color="black"
          border={`0.1rem solid ${theme.main}`}
          textAlign="center"
          width="100%"
        >
          {t('header.logout')}
        </Button>
      ),
      className: 'logout-button',
    },
  ];

  return (
    <Flex alignItems="center" gap="l">
      {/* <Tooltip title={t('common.settings')}>
        <StyledButton icon={faGear} color="black" iconWidth="2xl" />
      </Tooltip>

      <Tooltip title={t('common.notifications')}>
        <StyledButton icon={faBell} color="black" iconWidth="2xl" />
      </Tooltip>

      <Tooltip title={t('common.share')}>
        <StyledButton
          icon={faArrowUpFromBracket}
          color="black"
          iconWidth="2xl"
        />
      </Tooltip> */}
      <Dropdown
        overlayStyle={{ width: '15rem', borderRadius: '0.5rem' }}
        menu={{ items }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <UserProfileWrapper>
          {user?.picture ? (
            <AtiraImage src={user.picture} />
          ) : (
            <AtiraIcon icon={faCircleUser} size="4x" color="main" />
          )}
        </UserProfileWrapper>
      </Dropdown>
    </Flex>
  );
};
