import { DeleteURLDto } from '../../model/url/dto/DeleteURLDto';
import { GetUserShortURLsDto } from '../../model/url/dto/GetUserShortURLsDto';
import { ShortenURLDto } from '../../model/url/dto/ShortenURLDto';
import { GenericDisableEnableURLDto } from '../../redux/url/dto/GenericDisableEnableURLDto';
import { AtiraApi } from '../AxiosClient';

export class URLAxios {
  static getShortURL(dto: ShortenURLDto) {
    return AtiraApi.post(`/url/shorten`, dto).then(({ data }) => data);
  }

  static getUseShortURLs(dto: GetUserShortURLsDto) {
    return AtiraApi.post('/url/get', dto).then(({ data }) => data);
  }

  static deleteURL(dto: DeleteURLDto) {
    return AtiraApi.post('url/delete', dto).then(({ data }) => data);
  }
  static async disableUserURL(dto: GenericDisableEnableURLDto) {
    return AtiraApi.post(`/url/disable`, dto).then(({ data }) => data);
  }
  static async enableUserURL(dto: GenericDisableEnableURLDto) {
    return AtiraApi.post(`/url/enable`, dto).then(({ data }) => data);
  }
}
