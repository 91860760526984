import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { ImageUpload } from '../../../components/ImageUpload';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import i18n, { AppLangs } from '../../../i18n';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkSocialHandlers } from '../../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { UserKind } from '../../../model/user/types/UserKind.enum';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { truncateString } from '../../../utils/String';

const socialMediaIcons = {
  WHATSAPP: faWhatsapp,
  INSTAGRAM: faInstagram,
  TWITTER: faXTwitter,
  FACEBOOK: faFacebook,
  YOUTUBE: faYoutube,
  TELEGRAM: faTelegram,
  TIKTOK: faTiktok,
};

const placeholders: Record<AtiraLinkSocialHandlers, string> = {
  WHATSAPP: 'https://wa.me/905...',
  INSTAGRAM: 'https://www.instagram.com/...',
  TWITTER: 'https://www.twitter.com/...',
  FACEBOOK: 'https://www.facebook.com/...',
  YOUTUBE: 'https://www.youtube.com/...',
  TELEGRAM: 'https://www.telegram.com/...',
  TIKTOK: 'https://www.tiktok.com/...',
};

const getSocialMediaOptions = (t: TFunction) => [
  { value: 'WHATSAPP', label: t('common.whatsapp') },
  { value: 'INSTAGRAM', label: t('common.instagram') },
  { value: 'TWITTER', label: t('common.twitter') },
  { value: 'FACEBOOK', label: t('common.facebook') },
  { value: 'YOUTUBE', label: t('common.youtube') },
  { value: 'TELEGRAM', label: t('common.telegram') },
  { value: 'TIKTOK', label: t('common.tiktok') },
];

const getPlaceholder = (value: AtiraLinkSocialHandlers): string => {
  return placeholders[value] || '';
};

const validateTitle = (t: TFunction, value?: string) => {
  if (!value || value.length < 3) {
    return t('atira_link.create.content.error.title');
  }

  return undefined;
};

const validateLinkName = (t: TFunction, value?: string) => {
  if (!value || value.length > 7 || value.length < 3) {
    return t('atira_link.create.content.error.link_name');
  }

  return undefined;
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1.3rem;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const InputWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.darkSub};
  border-radius: ${Rounded.md};
  gap: ${Spacing.m};
  align-items: center;
  padding-block: 0.2rem;

  padding-left: ${i18n.language === AppLangs.AR ? '0' : Spacing.s};
  padding-right: ${i18n.language === AppLangs.AR ? Spacing.s : '0'};
`;

const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.transparent};
  font-size: 1rem;
  font-weight: 600;
`;

const InputLabel = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
`;

const StyledTextarea = styled(Textarea)`
  background-color: ${({ theme }) => theme.transparent};
  border: 0;
  font-size: 1rem;
  font-weight: 600;
  resize: vertical;
`;

const SocialsWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  gap: ${Spacing.s};
  overflow-y: scroll;
  max-height: 9rem;
`;

const SocialContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${Spacing.s};
  padding-inline: ${Spacing.s};
`;

const ImageUploadWrapper = styled(Flex)`
  .ant-upload-wrapper {
    border: ${({ theme }) => `1px solid ${theme.main}`} !important;
    border-radius: ${Rounded.circle} !important;
  }

  .ant-upload-select {
    border-radius: ${Rounded.circle} !important;
    border: 0 !important;
    overflow: hidden !important;
  }

  .ant-upload-list-picture-card {
    display: block !important;
    position: relative !important;
  }

  .ant-upload-list-item {
    border: 0 !important;
    padding: 0 !important;
    border-radius: ${Rounded.circle} !important;
    overflow: hidden !important;
  }

  .ant-upload-list-item-container {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .ant-upload-list-item-actions {
    z-index: 5000 !important;
  }

  .ant-upload-list-item::before {
    width: 100% !important;
    height: 100% !important;
  }

  button {
    width: 100%;
    height: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.lightMain} !important;
    }
  }
`;

const UpgradeLink = styled(Link)`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  margin-inline: 0.3rem;
  padding: ${Spacing.s};
  border-radius: ${Rounded.md};
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.white};
    opacity: 0.8;
  }
`;

const SocialCreationWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  flex: 1;

  border-right: ${({ theme }) =>
    i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
  border-left: ${({ theme }) =>
    i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};

  padding-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.s};
  padding-left: ${i18n.language === AppLangs.AR ? Spacing.s : '0'};
`;

export const AtiraLinkContentTab: React.FC = () => {
  const [selectedOption, setSelectedOption] =
    useState<AtiraLinkSocialHandlers | null>(null);
  const [socialLink, setSocialLink] = useState('');
  const [socialLabel, setSocialLabel] = useState('');

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const aitralink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);

  const { t } = useTranslation();
  const { theme } = useTheme();
  const { control, watch, setValue } = useFormContext<CreateAtiraLinkDto>();
  const { append, remove } = useFieldArray({
    control,
    name: 'socials',
  });

  const [socials, linkName, image] = watch(['socials', 'linkName', 'image']);

  const editMode = Boolean(aitralink?._id);

  const onAddSocial = () => {
    if (selectedOption && socialLink.trim() && socialLabel.trim()) {
      append({ handle: selectedOption, label: socialLabel, url: socialLink });
      setSelectedOption(null);
      setSocialLink('');
      setSocialLabel('');
    }
  };

  return (
    <Flex flexDirection="column" gap="l" width={'100%'} flex={9}>
      <SectionWrapper>
        <SectionTitle>{t('common.link')}</SectionTitle>

        <Section>
          <Text color="gray">
            {t(
              `atira_link.create.content.link_name.desdcription${editMode ? '.edit' : ''}`,
            )}
            <span style={{ fontWeight: 'bold' }}> https://atrl.ink/</span>
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              {linkName}
            </span>
          </Text>

          {!editMode ? (
            <Text color="gray">
              {t('atira_link.create.content.link_name.length.note')}
            </Text>
          ) : null}

          <InputWrapper>
            <InputLabel>
              <span style={{ color: theme.red }}>* </span>
              {t('atira_link.create.content.link_name')}:
            </InputLabel>

            <Controller
              control={control}
              name="linkName"
              rules={{
                required: true,
                validate: (value) => validateLinkName(t, value),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <StyledInput
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.toLowerCase().trim())
                  }
                  maxLength={7}
                  minLength={3}
                  errorMessage={error?.message}
                  valid={!error}
                  disabled={editMode}
                />
              )}
            />
          </InputWrapper>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.profile')}</SectionTitle>

        <Section>
          <Flex alignItems="center" gap="m">
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, value } }) => (
                <ImageUploadWrapper>
                  <ImageUpload
                    maxCount={1}
                    onRemove={() => setValue('image', '')}
                    onChange={onChange}
                    button={
                      <Button
                        icon={value ? undefined : faCamera}
                        borderRadius="pill"
                        color="main"
                        iconWidth="3x"
                        backgroundColor="transparent"
                      />
                    }
                    listType="picture-card"
                  />
                </ImageUploadWrapper>
              )}
            />

            <Flex flexDirection="column" gap="m" width={'100%'}>
              <InputWrapper>
                <InputLabel>
                  <span style={{ color: theme.red }}>* </span>
                  {t('common.title')}:
                </InputLabel>

                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: true,
                    validate: (value) => validateTitle(t, value),
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'atira_link.create.content.title.placeholder',
                      )}
                      errorMessage={error?.message}
                      valid={!error}
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper>
                <InputLabel>{t('common.text')}:</InputLabel>

                <Controller
                  control={control}
                  name="bio"
                  render={({ field: { value, onChange } }) => (
                    <StyledTextarea
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'atira_link.create.content.text.placeholder',
                      )}
                    />
                  )}
                />
              </InputWrapper>
            </Flex>
          </Flex>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.custom_links')}</SectionTitle>

        <Section>
          <Flex flexDirection="column" gap="m">
            <Flex gap="l" justifyContent="space-between">
              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.phone')}:</InputLabel>

                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type="number"
                      placeholder={'+0123456789'}
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.email')}:</InputLabel>

                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="email"
                      placeholder={'any@gmail.com'}
                      color="main"
                    />
                  )}
                />
              </InputWrapper>
            </Flex>

            <Flex gap="l" justifyContent="space-between">
              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.website')}:</InputLabel>
                <Controller
                  control={control}
                  name="website"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder="https://yourdomain.com"
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.map')}:</InputLabel>
                <Controller
                  control={control}
                  name="map"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder="https://maps.app.goo.gl/..."
                    />
                  )}
                />
              </InputWrapper>
            </Flex>

            <InputWrapper>
              <InputLabel>{`${t('common.custom')} 1`}:</InputLabel>

              <Controller
                control={control}
                name={`customLinks.${0}`}
                render={({ field: { value, onChange } }) => (
                  <StyledInput
                    value={value}
                    onChange={onChange}
                    placeholder="https://yourdomain.com"
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper>
              <InputLabel>{`${t('common.custom')} 2`}:</InputLabel>
              <Controller
                control={control}
                name={`customLinks.${1}`}
                render={({ field: { value, onChange } }) => (
                  <StyledInput
                    value={value}
                    onChange={onChange}
                    placeholder="https://yourdomain.com"
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper>
              <InputLabel>{`${t('common.custom')} 3`}:</InputLabel>

              {loggedInUser?.kind === UserKind.FREE ? (
                <StyledInput
                  readOnly={loggedInUser?.kind === UserKind.FREE}
                  placeholder="https://yourdomain.com"
                />
              ) : (
                <Controller
                  control={control}
                  name={`customLinks.${2}`}
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      readOnly={loggedInUser?.kind === UserKind.FREE}
                      value={value}
                      onChange={onChange}
                      placeholder="https://yourdomain.com"
                    />
                  )}
                />
              )}

              {loggedInUser?.kind === UserKind.FREE ? (
                <UpgradeLink to={''}>{t('common.upgrade')}</UpgradeLink>
              ) : null}
            </InputWrapper>
          </Flex>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.social_media')}</SectionTitle>

        <Section flexDirection="row">
          <SocialCreationWrapper>
            <DropDown
              title={t('atira_link.create.content.select_social')}
              value={{
                label: selectedOption
                  ? t(`common.${selectedOption?.toLowerCase()}`)
                  : t('common.dropdown.select'),

                value: selectedOption,
              }}
              options={getSocialMediaOptions(t)}
              onChange={(v) => setSelectedOption(v as AtiraLinkSocialHandlers)}
            />

            <Flex gap="s" flexDirection="column">
              <InputWrapper>
                <InputLabel>
                  {t('atira_link.create.content.social_label.title')}:
                </InputLabel>

                <StyledInput
                  id="social-label"
                  value={socialLabel}
                  placeholder={t(
                    'atira_link.create.content.social_label.plcaeholder',
                  )}
                  onChange={(e) =>
                    setSocialLabel(e.currentTarget.value.toUpperCase())
                  }
                />
              </InputWrapper>

              <Flex gap="s">
                <InputWrapper flexGrow={1}>
                  <InputLabel>{t('common.enter_your_url')}:</InputLabel>

                  <StyledInput
                    id="social-url"
                    value={socialLink}
                    placeholder={
                      selectedOption ? getPlaceholder(selectedOption) : ''
                    }
                    onChange={(e) => setSocialLink(e.currentTarget.value)}
                  />
                </InputWrapper>

                <Button
                  icon={faPlus}
                  onClick={onAddSocial}
                  disabled={!selectedOption || !socialLink || !socialLabel}
                />
              </Flex>
            </Flex>
          </SocialCreationWrapper>

          <SocialsWrapper>
            {socials?.map((item, index) => (
              <SocialContainer key={`${item.label}-${index}`}>
                <Flex alignItems="center" gap="s">
                  <AtiraIcon icon={socialMediaIcons[item.handle]} size="3x" />

                  {item.label?.length > 20 ? (
                    <Tooltip title={item.label}>
                      <Text fontWeight={'600'}>
                        ({truncateString(item.label, 20)})
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text fontWeight={'600'}>
                      ({truncateString(item.label, 20)})
                    </Text>
                  )}

                  {item.url?.length > 20 ? (
                    <Tooltip title={item.url}>
                      <Text>{truncateString(item.url, 20)}</Text>
                    </Tooltip>
                  ) : (
                    <Text>{truncateString(item.url, 20)}</Text>
                  )}
                </Flex>

                <Button onClick={() => remove(index)} icon={faMinus} />
              </SocialContainer>
            ))}
          </SocialsWrapper>
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
