import React, { useEffect } from 'react';

import { Flex } from '../../components/Flex';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { UserURLs } from './UserURLs';

export const URLRoute: React.FC = () => {
  const dispatch = useAppDispatch();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  useEffect(() => {
    const getUserShortURLs = async () => {
      try {
        await dispatch(
          shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
        ).unwrap();
      } catch (e: any) {
        console.log(e);
        AtiraToast.apiError(e);
      }
    };

    getUserShortURLs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column">
      <UserURLs />
    </Flex>
  );
};
