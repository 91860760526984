import AtiraGrupIcon from './atira-grup-icon.svg';
import GoogleIcon from './google.svg';

export const Images = {
  HeroPhoto: require('./hero.jpg'),
  HeroBackground: require('./hero-bg.jpg'),
  FormTheme1: require('./form-theme1.png'),
  FormTheme2: require('./form-theme2.png'),
  FormTheme3: require('./form-theme3.png'),
  AtiraSpaceLogo: require('./atiraspace-logo.png'),
  FeaturesBackground: require('./features-bg.jpg'),
  FeaturesFirstCircle: require('./hero-first-circle-img.png'),
  FeaturesSecondCircle: require('./hero-second-circle-img.png'),
  FeaturesFirstSection: require('./features-first-section.jpg'),
  FeaturesSecondSection: require('./features-second-section.jpg'),
  FeaturesNerve1: require('./features-nerve-1.jpeg'),
  FeaturesNerve2: require('./features-nerve-2.jpeg'),
  FeaturesNerve3: require('./features-nerve-3.png'),
  FeaturesSecondSection1: require('./features-second-section-1.png'),
  FeaturesSecondSection2: require('./features-second-section-2.png'),
  FeaturesSecondSection3: require('./features-second-section-3.png'),
  AtiraGrupIcon,
  GoogleIcon,
  //
  Themes: {
    BasicFull: require('./themes/basic-full.png'),
    BasicMini: require('./themes/basic-mini.png'),
    BasicSide: require('./themes/basic-side.png'),
    DreamFull: require('./themes/dream-full.png'),
    DreamMini: require('./themes/dream-mini.png'),
    DreamSide: require('./themes/dream-side.png'),
    FemosFull: require('./themes/femos-full.png'),
    FemosMini: require('./themes/femos-mini.png'),
    FemosSide: require('./themes/femos-side.png'),
    JillyFull: require('./themes/jilly-full.png'),
    JillyMini: require('./themes/jilly-mini.png'),
    JillySide: require('./themes/jilly-side.png'),
    DemonaFull: require('./themes/demona-full.png'),
    DemonaMini: require('./themes/demona-mini.png'),
    DemonaSide: require('./themes/demona-side.png'),
  },
  //
};
