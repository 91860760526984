import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { QRAxios } from '../../axios/qr/qr.axios';
import { CreateQRDto } from '../../model/qr/dto/CreateQRDto';
import { GetUserQRsDto } from '../../model/qr/dto/GetUserQRsDto';
import { GetUserQRsResponseDto } from '../../model/qr/dto/GetUserQRsResponseDto';
import { QRResponseDto } from '../../model/qr/dto/QrResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { QRAdapterReduxEntry } from './QR-slice.redux-entries';
import { DeleteQRDto } from '../../model/qr/dto/DeleteQRDto';

interface QRReducer {}

const createQR = AtiraThunk<QRResponseDto, CreateQRDto>(`/qr`, (dto) =>
  QRAxios.CreateQR(dto),
);

const getUserQRs = AtiraThunk<GetUserQRsResponseDto, GetUserQRsDto>(
  `/qr/get`,
  (dto) => QRAxios.getUserQRs(dto),
);

const deleteQR = AtiraThunk<void, DeleteQRDto>(`/qr/delete`, (dto) =>
  QRAxios.deleteQR(dto),
);

const initialState = Object.freeze<QRReducer>({});

const QRAdapter = createEntityAdapter<QRAdapterReduxEntry>();
export const QRAdapterSelectors = QRAdapter.getSelectors();

const qrSlice = createSlice({
  name: 'QRSlice',
  initialState: { ...initialState, qrAdapter: QRAdapter.getInitialState() },
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(createQR.fulfilled, (state, action) => {
      const { qr } = action.payload;

      const userId = action.meta.arg.userId;

      const userQRs = QRAdapterSelectors.selectById(state.qrAdapter, userId);

      QRAdapter.upsertOne(state.qrAdapter, {
        id: userId,
        QRs: [...(userQRs?.QRs || []), qr].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        ),
      });
    });

    addCase(getUserQRs.fulfilled, (state, action) => {
      QRAdapter.upsertOne(state.qrAdapter, {
        id: action.meta.arg.userId,
        QRs: action.payload.data,
      });
    });
  },
});

export const QRActions = {
  createQR,
  getUserQRs,
  deleteQR,
};

export default qrSlice.reducer;
