import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { Text } from '../../components/Text';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';

const Header = styled(Flex)`
  justify-content: space-between;
  padding: ${Spacing.s};
  background-color: ${({ theme }) => theme.main};
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: fit-content;
  }
`;

const PageWrapper = styled(Flex)`
  background-image: url(${Images.FeaturesBackground});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: ${Spacing.xxl} 0;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 37rem;
  }
`;

const PageInnerWarpper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 25rem;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 21rem;
  }
`;

const PageWrapperBlur = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: -1;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.white};
`;

type RegisterWrapperProps = {
  children: React.ReactNode;
};

export const RegisterWrapper: React.FC<RegisterWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  return (
    <Flex flex={1} justifyContent="center" backgroundColor={'main'}>
      <Container>
        <Flex flexDirection="column" height={'fit-content'} boxShadow="SM">
          <Header>
            <Link to="/features">
              <AtiraImage src={Images.AtiraSpaceLogo} width="13rem" />
            </Link>
            <LanguageSwitcher />
          </Header>

          <PageWrapper>
            <PageWrapperBlur />
            <PageInnerWarpper>
              <Flex
                borderBottom={`1px solid ${theme.lightergray}`}
                paddingBottom="m"
                flexDirection="column"
                gap="s"
              >
                <Text
                  color="main"
                  fontWeight={'600'}
                  fontSize="l"
                  align="center"
                >
                  {t('register.welcome_message')}
                </Text>
              </Flex>

              {children}
            </PageInnerWarpper>
          </PageWrapper>
        </Flex>

        <Flex justifyContent="space-between" padding="s">
          <Flex gap="s" alignItems="center">
            <Text color="white">{t('common.need_help')}</Text>
            <StyledLink to={'/'}>{t('common.contact_us')}</StyledLink>
          </Flex>

          <Text color="white">{t('app.atiralink')}</Text>
        </Flex>
      </Container>
    </Flex>
  );
};
