import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraIcon } from '../../components/AtiraIcon';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { PageHeader } from '../../components/PageHeader';
import { Text } from '../../components/Text';
import { UserHeader } from '../../components/UserHeader';
import { atiraLinkSliceSelectors } from '../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const socialMediaIcons = {
  WHATSAPP: faWhatsapp,
  INSTAGRAM: faInstagram,
  TWITTER: faXTwitter,
  FACEBOOK: faFacebook,
  YOUTUBE: faYoutube,
  TELEGRAM: faTelegram,
  TIKTOK: faTiktok,
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const InputSection = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  align-items: center;
  width: 25rem;
  gap: 5px;
`;

const StyledButton = styled(CopyButton)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  margin: 0rem;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionSecondTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLinkUrl = `https://atrl.ink/${userLink?.linkName}`;

  const activityVisits =
    (atiraLink?.stats?.mobile.visits || 0) +
    (atiraLink?.stats?.desktop.visits || 0);
  const activityUniqueVisits =
    (atiraLink?.stats?.mobile.uniqueVisits || 0) +
    (atiraLink?.stats?.desktop.uniqueVisits || 0);
  const activityClicks =
    (atiraLink?.stats?.mobile.clicks || 0) +
    (atiraLink?.stats?.desktop.clicks || 0);
  const activityUniqueClicks =
    (atiraLink?.stats?.mobile.uniqueClicks || 0) +
    (atiraLink?.stats?.desktop.uniqueClicks || 0);
  const activityCtr = (activityClicks / activityVisits) * 100 || 0;

  useEffect(() => {
    dispatch(AtiraLinkActions.getUserAtiraLink({ userId }));
  }, [dispatch, userId]);

  return (
    <Flex gap="m" margin="m" flexDirection="column" flex={1}>
      <PageHeader
        leftComponent={
          !isEmpty(userLink) ? (
            <Flex gap="m">
              <InputSection>
                <Text color="main" fontSize="l" fontWeight={'bolder'} cutText>
                  {t('common.your_link')}:
                </Text>
                <Text fontSize="l">{userLinkUrl}</Text>
              </InputSection>
              <StyledButton copy={userLinkUrl} />
            </Flex>
          ) : (
            <InputSection
              width={'17rem'}
              cursor="pointer"
              gap="l"
              onClick={() => navigate('/my-link')}
            >
              <Text color="main" fontSize="l" fontWeight={'bolder'}>
                {t('dashboard.create_link')}
              </Text>
              <AtiraIcon size="2x" icon={faArrowRight} />
            </InputSection>
          )
        }
        rightComponent={
          <Flex justifyContent="end">
            <UserHeader />
          </Flex>
        }
      />

      <Flex gap="m" flex={1}>
        <SectionWrapper width={'100%'}>
          <Section>
            <SectionTitle>{t('common.activity')}</SectionTitle>

            <Row justify={'space-between'}>
              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>{activityVisits}</Text>
                  <StyledNumber>{t('common.views')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>{activityUniqueVisits}</Text>
                  <StyledNumber>{t('common.unique_views')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>{activityClicks}</Text>
                  <StyledNumber>{t('common.clicks')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>{activityUniqueClicks}</Text>
                  <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn xl={4}>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>{activityCtr}%</Text>
                  <StyledNumber fontWeight={'bolder'}>
                    {t('common.ctr')}
                  </StyledNumber>
                </Flex>
              </AtiraColumn>
            </Row>
          </Section>

          <Section>
            <SectionTitle>{t('common.device')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <SectionSecondTitle>{t('common.mobil')}</SectionSecondTitle>

              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.mobile.visits}
                    </Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.mobile.uniqueVisits}
                    </Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.mobile.clicks}
                    </Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.mobile.uniqueClicks}
                    </Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.mobile.ctr}
                    </Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
              <SectionSecondTitle>{t('common.desktop')}</SectionSecondTitle>
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.desktop.visits}
                    </Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.desktop.uniqueVisits}
                    </Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.desktop.clicks}
                    </Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.desktop.uniqueClicks}
                    </Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.desktop.ctr}
                    </Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>

          <Section>
            <SectionTitle> {t('common.social_media')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex flexDirection="column" gap="m">
                    {atiraLink?.stats?.clicks?.map((clickItem, index) => (
                      <Flex gap="s" alignItems="center" key={index}>
                        <AtiraIcon
                          icon={socialMediaIcons[clickItem.handler]}
                          size="2x"
                        />
                        <Text fontWeight={'bolder'}>{clickItem.count}</Text>{' '}
                        <StyledNumber>
                          {t(`common.${clickItem.handler.toLowerCase()}`)}
                        </StyledNumber>
                      </Flex>
                    ))}
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>

          <Section>
            <SectionTitle>{t('common.country_and_visits')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>
                      {atiraLink?.stats?.visitCountries &&
                      atiraLink.stats.visitCountries.length > 0 ? (
                        atiraLink.stats.visitCountries.map((country, index) => (
                          <Flex key={index}>
                            {country.countryName}: {country.visits}
                          </Flex>
                        ))
                      ) : (
                        <Flex>
                          <Text>0 {t('common.views')}</Text>
                        </Flex>
                      )}
                    </Text>
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>
        </SectionWrapper>
      </Flex>
    </Flex>
  );
};
