import { AxiosError } from 'axios';

import i18n from '../i18n';
import { AtiraLinkActions } from '../redux/nerve/atira-link.slice';
import store from '../redux/store';
import { userActions } from '../redux/user/user.slice';
import { AtiraToast } from '../utils/AtiraToast';

/** Add actions here that are needed to be dispatched at the start of the app; */
export async function onAppStart() {
  const dispatch = store.store.dispatch;
  const t = i18n.t;

  try {
    const state = store.store.getState();
    const userId = state.user.loggedInUserId;

    if (userId) {
      await dispatch(userActions.refreshToken()).unwrap();
      dispatch(userActions.getLoggedInUser({ userId }));
      dispatch(AtiraLinkActions.getUserAtiraLink({ userId }));
    }
  } catch (e: any) {
    const error = e as AxiosError;

    if (error.response?.status === 403) {
      AtiraToast.error(t('app.jwt.error'));
      dispatch(userActions.logout());
    }
  }
}
