import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons/faTableColumns';
import { Menu, MenuProps } from 'antd';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { SideBarRoutes } from '../routes/types/SideBarRoutes.enum';
import { Breakpoints } from '../theme/Breakpoints';
import { Spacing } from '../theme/Spacing';
import { Theme } from '../theme/Theme';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { AtiraImage } from './Image';

const getMenuItems = (config: {
  t: TFunction;
  navigate: NavigateFunction;
  collapsed: boolean;
}): Required<MenuProps>['items'] => {
  const { t, navigate, collapsed } = config;

  return [
    {
      key: 'atira-link',
      label: !collapsed ? (
        <Flex>
          <AtiraImage src={Images.AtiraSpaceLogo} />
        </Flex>
      ) : (
        <AtiraImage src={Images.AtiraGrupIcon} />
      ),
      title: '',
    },
    {
      key: SideBarRoutes.DASHBOARD,
      label: !collapsed ? (
        <Flex alignItems="center" gap="s">
          <Flex width={'20%'}>
            <AtiraIcon icon={faTableColumns} />
          </Flex>
          {t('side_menu.dashboard')}
        </Flex>
      ) : (
        <AtiraIcon icon={faTableColumns} />
      ),
      onClick: () => navigate(SideBarRoutes.DASHBOARD),
      title: collapsed ? t('side_menu.dashboard') : '',
    },
    {
      key: SideBarRoutes.MY_LINK,
      label: !collapsed ? (
        <Flex alignItems="center" gap="s">
          <Flex width={'20%'}>
            <AtiraIcon icon={faUpRightFromSquare} />
          </Flex>
          {t('side_menu.my_link')}
        </Flex>
      ) : (
        <AtiraIcon icon={faUpRightFromSquare} />
      ),
      onClick: () => navigate(SideBarRoutes.MY_LINK),
      title: collapsed ? t('side_menu.my_link') : '',
    },
  ];
};

const StyledMenu = styled(Menu)`
  position: relative;
  background-color: ${Theme.transparent};
  padding: 0;
  border-inline-end: 0 !important;

  .ant-menu-item {
    padding-left: 24px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0px;
    font-size: 1.6rem;
    color: ${Theme.sub};
    border-radius: 0px;
    height: 3.3rem;
    width: 100%;
    justify-content: center;
  }

  .ant-menu-item:first-child {
    margin: ${Spacing.m} 0;
    justify-content: center;
  }

  .ant-menu-item:not(:first-child).ant-menu-item-selected {
    background-color: rgba(235, 235, 235, 0.6) !important;
    color: ${Theme.sub} !important;
  }

  .ant-menu-item:not(:first-child):hover {
    background-color: rgba(235, 235, 235, 0.3) !important;
    color: ${Theme.sub} !important;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    .ant-menu-item {
      justify-content: space-between;
    }
  }
`;

export const SideMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getSelectedKey = useMemo(() => {
    switch (true) {
      case pathname.startsWith(SideBarRoutes.MY_LINK):
        return SideBarRoutes.MY_LINK;
      default:
        return pathname;
    }
  }, [pathname]);

  return (
    <StyledMenu
      mode="inline"
      theme="dark"
      items={getMenuItems({ t, navigate, collapsed })}
      selectedKeys={[getSelectedKey]}
    />
  );
};
