import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { QRRoute } from '../QR-generator/QRRoute';
import { URLRoute } from '../url/URLRoute';
import { Analytics } from './Analytics';

const StyledDropDown = styled(DropDown)`
  width: 12rem;
  margin-left: 25rem;
`;

export const AnalyticsRoute: React.FC = () => {
  const { t } = useTranslation();

  const DropdownChange = (value: any) => {
    console.log('Selected value:', value);
  };

  return (
    <Flex flex={1} padding="s">
      <Flex flex={1}>
        <AtiraTabs
          tabs={[
            { tabTitle: t('analytics.tab_link'), tabPanel: <Analytics /> },
            { tabTitle: t('analytics.tab_qrs'), tabPanel: <QRRoute /> },
            { tabTitle: t('analytics.tab_urls'), tabPanel: <URLRoute /> },
          ]}
          middleComponent={<StyledDropDown onChange={DropdownChange} />}
          sideComponent={<UserHeader />}
        />
      </Flex>
    </Flex>
  );
};
