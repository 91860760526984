import { faImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { ColorPicker } from 'antd';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { ImageUpload } from '../../../components/ImageUpload';
import { Text } from '../../../components/Text';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkButtonBorderRadius } from '../../../model/atira-link/types/AtiraLinkButtonBorderRadius';
import { AtiraLinkIconKind } from '../../../model/atira-link/types/AtiraLinkIconsKind';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1.3rem;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const StyledColorPicker = styled(ColorPicker)`
  width: 6rem;
  height: 6rem;
  border-radius: ${Rounded.circle};
  padding: 0;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.black};

  &:hover {
    border: 2px solid ${({ theme }) => theme.black};
  }

  .ant-color-picker-color-block {
    width: 100%;
    height: 100%;
  }

  &.ant-color-picker-trigger-active {
    box-shadow: none;
  }
`;

const MiniColorPicker = styled(ColorPicker)`
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.black};

  &:hover {
    border: 1px solid ${({ theme }) => theme.black};
  }

  .ant-color-picker-color-block {
    width: 100%;
    height: 100%;
  }

  &.ant-color-picker-trigger-active {
    box-shadow: none;
  }
`;

const SelectMediaButton = styled(Button)`
  width: 6rem;
  height: 6rem;
  border-radius: ${Rounded.circle};
  padding: ${Spacing.s};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BorderRadiusSelectFlex = styled(Flex)<{ selected?: boolean }>`
  height: 3rem;
  flex: 1;
  border: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.transparent}` : `2px solid ${theme.black}`};
  outline: ${({ selected, theme }) =>
    selected ? `4px solid ${theme.main}` : 'none'};
  cursor: pointer;
`;

const BlurContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.transparent};
  z-index: 100;
  flex-direction: column;
  flex: 1;
  gap: ${Spacing.l};
  opacity: 0.3;
  pointer-events: none;
`;

const StyledImageUploader = styled(ImageUpload)`
  .ant-upload-list-picture-circle {
    position: relative;

    .ant-upload-list-item-container {
      position: absolute !important;
      z-index: 100;
    }
  }

  .ant-upload {
    background-color: ${({ theme }) => theme.white} !important;
    border: 0;
  }

  .ant-upload-select {
    background-color: red;
  }

  .ant-upload-list-item .ant-upload-list-item-undefined {
    background-color: ${({ theme }) => theme.main};
  }
`;

const IconTypeChooseButton = styled(Button)<{ selected: boolean }>`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme, selected }) => (selected ? theme.main : theme.black)};
  padding: ${Spacing.s};
  border: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.main}` : `2px solid ${theme.black}`};
`;

enum BackgroundTypes {
  SOLID = 'SOLID',
  GRADIENT = 'GRADIENT',
  IMAGE = 'IMAGE',
}

export const AtiraLinkDesignTab: React.FC = () => {
  const [backgroundType, setBackroundType] = useState<BackgroundTypes | null>(
    null,
  );

  const { t } = useTranslation();

  const { theme } = useTheme();

  const { control, setValue, watch } = useFormContext<CreateAtiraLinkDto>();

  const gradientColorPickerDefaultValue = [
    {
      color: theme.accent,
      percent: 0,
    },
    {
      color: theme.main,
      percent: 100,
    },
  ];

  const [buttonBackgroundColor, buttonBorderRadius, iconsKind] = watch([
    'styles.button.backgroundColor',
    'styles.button.borderRadius',
    'styles.icons.kind',
  ]);

  return (
    <Flex flexDirection="column" gap="l" width={'100%'} flex={9}>
      <Text fontSize="xxl" color="gray">
        Coming soon...
      </Text>
      <BlurContainer>
        <SectionWrapper>
          <SectionTitle>{t('common.background')}</SectionTitle>

          <Section>
            <Flex justifyContent="space-between">
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Controller
                  control={control}
                  name="styles.background.color"
                  render={({ field: { value, onChange } }) => (
                    <StyledColorPicker
                      value={
                        backgroundType === BackgroundTypes.SOLID
                          ? value
                          : theme.main
                      }
                      onChange={(color) => {
                        onChange(color.toHexString());
                        setBackroundType(BackgroundTypes.SOLID);
                      }}
                      defaultValue={theme.main}
                    />
                  )}
                />

                <Text>{t('common.color')}</Text>
              </Flex>

              <Flex flexDirection="column" gap="m" alignItems="center">
                <Controller
                  control={control}
                  name="styles.background.color"
                  render={({ field: { value, onChange } }) => (
                    <StyledColorPicker
                      value={
                        backgroundType === BackgroundTypes.GRADIENT
                          ? value
                          : gradientColorPickerDefaultValue
                      }
                      onChange={(color) => {
                        onChange(color.toHexString());
                        setBackroundType(BackgroundTypes.GRADIENT);
                      }}
                      style={{ border: '0' }}
                      defaultValue={gradientColorPickerDefaultValue}
                      mode={['gradient']}
                    />
                  )}
                />

                <Text>{t('common.gradient')}</Text>
              </Flex>

              <Flex flexDirection="column" gap="m" alignItems="center">
                <Controller
                  control={control}
                  name="styles.background.color"
                  render={({ field: { onChange } }) => (
                    <StyledImageUploader
                      listType="picture-circle"
                      onChange={(f) => {
                        onChange(f);
                        setBackroundType(BackgroundTypes.IMAGE);
                      }}
                      button={
                        <SelectMediaButton
                          disabled
                          icon={faImage}
                          iconWidth="3x"
                        />
                      }
                    />
                  )}
                />

                <Text>{t('common.image')}</Text>
              </Flex>

              <Flex flexDirection="column" gap="m" alignItems="center">
                <SelectMediaButton disabled icon={faVideo} iconWidth="3x" />

                <Text>{t('common.video')}</Text>
              </Flex>
            </Flex>
          </Section>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t('common.buttons')}</SectionTitle>

          <Section>
            <Flex flexDirection="column" gap="l">
              <Flex flexDirection="column" gap="s">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{t('common.border')}</Text>

                  <Flex alignItems="center" gap="s">
                    <Controller
                      control={control}
                      name={`styles.button.borderColor`}
                      render={({ field: { value, onChange } }) => (
                        <MiniColorPicker
                          value={value}
                          onChange={(c) => onChange(c.toHexString())}
                          style={{ padding: '0' }}
                        />
                      )}
                    />
                  </Flex>
                </Flex>

                <Flex justifyContent="space-around" gap="xl">
                  <BorderRadiusSelectFlex
                    selected={
                      buttonBorderRadius === AtiraLinkButtonBorderRadius.ROUNDED
                    }
                    borderRadius="xl"
                    onClick={() =>
                      setValue(
                        'styles.button.borderRadius',
                        AtiraLinkButtonBorderRadius.ROUNDED,
                      )
                    }
                  />

                  <BorderRadiusSelectFlex
                    selected={
                      buttonBorderRadius === AtiraLinkButtonBorderRadius.CIRCLE
                    }
                    borderRadius="pill"
                    onClick={() =>
                      setValue(
                        'styles.button.borderRadius',
                        AtiraLinkButtonBorderRadius.CIRCLE,
                      )
                    }
                  />

                  <BorderRadiusSelectFlex
                    selected={
                      buttonBorderRadius === AtiraLinkButtonBorderRadius.SQUARE
                    }
                    borderRadius="none"
                    onClick={() =>
                      setValue(
                        'styles.button.borderRadius',
                        AtiraLinkButtonBorderRadius.SQUARE,
                      )
                    }
                  />
                </Flex>
              </Flex>

              <Flex flexDirection="column" gap="s">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{t('common.fill')}</Text>

                  <Flex alignItems="center" gap="s">
                    <Controller
                      control={control}
                      name="styles.button.backgroundColor"
                      render={({ field: { value, onChange } }) => (
                        <MiniColorPicker
                          value={value}
                          onChange={(c) => onChange(c.toHexString())}
                          style={{ padding: '0' }}
                        />
                      )}
                    />
                  </Flex>
                </Flex>

                <Flex gap="xl">
                  <BorderRadiusSelectFlex
                    backgroundColor={buttonBackgroundColor || theme.black}
                    borderRadius="xl"
                  />

                  <Flex flex={1} />

                  <Flex flex={1} />
                </Flex>
              </Flex>
            </Flex>
          </Section>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t('common.fonts')}</SectionTitle>

          <Section>
            <Flex alignItems="center" gap="xxl">
              <Controller
                control={control}
                name="styles.text.font"
                render={({ field: { value, onChange } }) => (
                  <DropDown
                    value={value}
                    containerStyle={{ width: '20rem' }}
                    defaultValue={{ label: 'AUTO', value: 'AUTO' }}
                    options={[{ label: 'AUTO', value: 'AUTO' }]}
                    onChange={(v) => onChange(v)}
                  />
                )}
              />

              <Flex alignItems="center" gap="s">
                <Controller
                  control={control}
                  name="styles.text.color"
                  render={({ field: { value, onChange } }) => (
                    <MiniColorPicker
                      value={value}
                      onChange={(c) => onChange(c.toHexString())}
                      style={{ padding: '0' }}
                    />
                  )}
                />

                <Text>{t('common.color')}</Text>
              </Flex>
            </Flex>
          </Section>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t('common.icons')}</SectionTitle>

          <Section>
            <Flex flexDirection="column" gap="l">
              <Flex justifyContent="space-between" alignItems="center">
                <Text>{t('common.color')}</Text>

                <Flex alignItems="center" gap="s">
                  <Controller
                    control={control}
                    name={`styles.icons.color`}
                    render={({ field: { value, onChange } }) => (
                      <MiniColorPicker
                        value={value}
                        onChange={(c) => onChange(c.toHexString())}
                        style={{ padding: '0' }}
                      />
                    )}
                  />
                </Flex>
              </Flex>

              <Flex gap="m" flexDirection="column">
                <Text>{t('common.type')}</Text>

                <Flex gap="l">
                  <IconTypeChooseButton
                    iconWidth="4x"
                    icon={faFaceSmile}
                    onClick={() =>
                      setValue('styles.icons.kind', AtiraLinkIconKind.SOLID)
                    }
                    selected={iconsKind === AtiraLinkIconKind.SOLID}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Section>
        </SectionWrapper>
      </BlurContainer>
    </Flex>
  );
};
