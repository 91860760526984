import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../components/AtiraColumn';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.l};
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionSecondTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
`;

export const Analytics: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex gap="m" flex={1}>
      <Flex flex={1} flexDirection="column">
        <SectionWrapper width={'100%'}>
          <Section>
            <SectionTitle>{t('common.activity')}</SectionTitle>

            <Row justify={'space-between'}>
              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>100</Text>
                  <StyledNumber>{t('common.views')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>70</Text>
                  <StyledNumber>{t('common.unique_views')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>47</Text>
                  <StyledNumber>{t('common.clicks')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>42</Text>
                  <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn xl={4}>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>47</Text>
                  <Text>%</Text>
                  <StyledNumber fontWeight={'bolder'}>
                    {t('common.ctr')}
                  </StyledNumber>
                </Flex>
              </AtiraColumn>
            </Row>
          </Section>

          <Section>
            <SectionTitle>{t('common.device')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <SectionSecondTitle>{t('common.mobil')}</SectionSecondTitle>

              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
              <SectionSecondTitle>{t('common.desktop')}</SectionSecondTitle>
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>

          <Section>
            <SectionTitle>{t('common.source')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <SectionSecondTitle>{t('common.instagram')}</SectionSecondTitle>

              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
              <SectionSecondTitle>{t('common.facebook')}</SectionSecondTitle>
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>
        </SectionWrapper>
      </Flex>

      <SectionWrapper width={'25%'}>
        <Section height={'100%'}>
          <SectionTitle>{t('common.audience')}</SectionTitle>
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
