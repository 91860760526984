import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkButtonBorderRadius } from '../../model/atira-link/types/AtiraLinkButtonBorderRadius';
import { AtiraLinkIconKind } from '../../model/atira-link/types/AtiraLinkIconsKind';
import { AtiraLinkImageBorderRadius } from '../../model/atira-link/types/AtiraLinkImageBorderRadius';
import { AtiraLinkThemes } from '../../model/atira-link/types/AtiraLinkThemes.enum';
import { atiraLinkSliceSelectors } from '../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraLinkPreviewPhone } from './components/AtiraLinkPreviewPhone';
import { AtiraLinkContentTab } from './tabs/AtiraLinkContentTab';
import { AtiraLinkDesignTab } from './tabs/AtiraLinkDesignTab';
import { AtiraLinkTemplatesTab } from './tabs/AtiraLinkTemplatesTab';

const HeaderPhoneWrapper = styled(Flex)`
  padding: ${Spacing.m};
  flex-direction: column;
  flex: 1;
  gap: ${Spacing.m};
  align-items: flex-end;
  position: sticky;
  top: 0;
  height: min-content;
`;

export const AtiraLinkRoute: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { theme } = useTheme();

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);

  const formMethods = useForm<CreateAtiraLinkDto>({
    defaultValues: {
      title: atiraLink?.title || '',
      email: atiraLink?.email || '',
      phone: atiraLink?.phone || '',
      website: (atiraLink as any)?.website || '',
      userId: loggedInUser?._id,
      theme: atiraLink?.theme || AtiraLinkThemes.BASIC,
      styles: atiraLink?.styles || {
        button: {
          backgroundColor: theme.white,
          borderRadius: AtiraLinkButtonBorderRadius.CIRCLE,
          borderColor: theme.black,
        },
        image: {
          borderRadius: AtiraLinkImageBorderRadius.CIRCLE,
        },
        icons: {
          color: theme.black,
          kind: AtiraLinkIconKind.SOLID,
        },
        text: {
          color: theme.black,
          font: 'AUTO',
        },
      },
      linkName: atiraLink?.linkName || '',
      bio: atiraLink?.bio,
      customLinks: atiraLink?.customLinks || [],
      image: atiraLink?.image,
      socials: atiraLink?.socials || [],
      map: atiraLink?.map,
    },
  });

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex flex={1}>
      <FormProvider {...formMethods}>
        <Flex width="80%">
          <AtiraTabs
            tabs={[
              {
                tabTitle: t('common.content'),
                tabPanel: <AtiraLinkContentTab />,
              },
              {
                tabTitle: t('common.templates'),
                tabPanel: <AtiraLinkTemplatesTab />,
              },
              {
                tabTitle: t('common.design'),
                tabPanel: <AtiraLinkDesignTab />,
              },
            ]}
            onChange={handleTabChange}
            activeTab={activeTab}
          />
        </Flex>

        <HeaderPhoneWrapper>
          <UserHeader />
          <AtiraLinkPreviewPhone loading={loading} setLoading={setLoading} />
        </HeaderPhoneWrapper>
      </FormProvider>
    </Flex>
  );
};
