import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { Col, Drawer, Layout, Row } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Images } from './assets';
import { AtiraIcon } from './components/AtiraIcon';
import { Button } from './components/Button';
import { Flex } from './components/Flex';
import { AtiraImage } from './components/Image';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { Text } from './components/Text';
import { Breakpoints } from './theme/Breakpoints';
import { Rounded } from './theme/Rounded';
import { Spacing } from './theme/Spacing';

const StyledContent = styled(Content)`
  background: linear-gradient(
    90deg,
    rgba(35, 40, 147, 1) 41%,
    rgba(151, 11, 170, 1) 76%
  );
`;

const StyledFooter = styled(Footer)`
  background: linear-gradient(
    90deg,
    rgb(151, 11, 170) 0%,
    rgb(35, 40, 147) 99%
  );
  padding: 4rem;
`;

const StyledHeader = styled(Header)`
  width: 100%;
  background-image: linear-gradient(
    90deg,
    rgb(151, 11, 170) 0%,
    rgb(35, 40, 147) 99%
  );
  position: sticky;
  top: 0rem;
  z-index: 999;
  height: auto;
  padding: 0;

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0.1rem 0.2rem;
  }
`;

const HeaderLink = styled(Link)`
  color: ${(props) => props.theme.white};
  text-decoration: none;
  border-radius: ${Rounded.sm};

  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.main};
  }
`;
const HeaderLinksSection = styled(Col)`
  display: flex;

  @media (max-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

const StartButton = styled(Button)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  margin: 0;
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.black};
  }
  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.1rem;
  }
`;

const HeroCircleAnimation = keyframes`
  from {
    transform: translateY(-1rem);
  } to {
    transform: translateY(1rem);
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.white};
  padding: 0.75rem 1rem;
  width: 20rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }
`;

const DrawerButton = styled(Col)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.TABLET}) {
    display: inline-block;
  }
`;

const FeatureWrapper = styled(Flex)`
  gap: ${Spacing.m};
  align-items: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const HeaderLogoWrapper = styled(Flex)`
  justify-content: center;
  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const HeroImageWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
`;

const HeroImageLeftCircle = styled(Flex)`
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  border-radius: ${Rounded.circle};
  background-size: 20rem;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 111;
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 3s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 6rem;
    width: 6rem;
    bottom: 1.5rem;
  }
`;

const HeroImageRightCircle = styled(Flex)`
  height: 5rem;
  width: 5rem;
  background-color: ${(props) => props.theme.darkPink};
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  border-radius: ${Rounded.circle};
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 6s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 8rem;
    width: 8rem;
    bottom: 1rem;
  }
`;

const Section = styled(Flex)`
  padding: 0 ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.xxxl};
  }
`;

const FirstSectionMobileImageWrapper = styled(Flex)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-title {
    font-size: 1.7rem;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 1rem;
  }
  .anticon-close svg {
    background-color: red;
    opacity: 0.7;
    height: 1.8rem;
    width: 1.8rem;
    padding: 0.2rem;
    border-radius: ${Rounded.sm};
    transition: all 0.2s;
  }
  .anticon-close svg:hover {
    opacity: 1;
  }
  .anticon-close svg path {
    color: ${({ theme }) => theme.black};
  }
  * {
    color: ${({ theme }) => theme.white};
  }
`;

const drawerStyle = {
  background:
    'linear-gradient(90deg, rgba(14, 5, 138, 1) 40%, rgba(255, 255, 255, 0) 100%)',
};

const DrawerLink = styled(Link)`
  font-size: 1.5rem;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

export const Features: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Flex>
      <Layout>
        <StyledHeader>
          <Row>
            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
              <HeaderLogoWrapper>
                <AtiraImage
                  src={Images.AtiraSpaceLogo}
                  style={{ maxWidth: '20rem' }}
                />
              </HeaderLogoWrapper>
            </Col>

            <HeaderLinksSection xs={0} sm={4} md={12} lg={8} xl={12}>
              <Flex
                gap="l"
                alignItems="center"
                justifyContent="center"
                width={'100%'}
              >
                <HeaderLink to={''}>{t('common.how_to')}</HeaderLink>
                <HeaderLink to={''}>{t('common.templates')}</HeaderLink>
                <HeaderLink to={''}>{t('common.pricing')}</HeaderLink>
                <HeaderLink to={''}>{t('common.help')}</HeaderLink>
              </Flex>
            </HeaderLinksSection>

            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
              <Row align={'middle'} style={{ height: '100%' }}>
                <DrawerButton xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Flex justifyContent="center">
                    <Button icon={faBars} onClick={showDrawer} />
                  </Flex>
                </DrawerButton>
                <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Flex alignItems="center" justifyContent="center">
                    <LanguageSwitcher />
                  </Flex>
                </Col>
                <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Flex justifyContent="center">
                    <StartButton onClick={() => navigate('/login')}>
                      {t('common.login')}
                    </StartButton>
                  </Flex>
                </Col>
                <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                  <Flex justifyContent="center">
                    <StartButton onClick={() => navigate('/signup')}>
                      {t('common.signup')}
                    </StartButton>
                  </Flex>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledHeader>

        <StyledContent>
          <Section marginTop="m">
            <Row justify={'space-around'} align={'middle'}>
              <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                <Flex flexDirection="column" gap="m">
                  <Text fontSize="_8xl" fontWeight={'bold'} color="white">
                    {t('app.name').toUpperCase()}
                  </Text>
                  <Text fontSize="xxxl" fontWeight={'bold'} color="white">
                    {t('app.description')}
                  </Text>
                  <Text fontSize="xl" color="white" lineHeight={'2.2rem'}>
                    {t('front_page.hero.paragraph')}
                  </Text>
                  <CallToAction onClick={() => navigate('/signup')}>
                    {t('front_page.hero.main_c2a')}
                  </CallToAction>
                </Flex>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                <HeroImageWrapper>
                  <HeroImageLeftCircle>
                    <AtiraImage src={Images.FeaturesFirstCircle} />
                  </HeroImageLeftCircle>
                  <AtiraImage
                    src={Images.FeaturesNerve1}
                    width="50%"
                    style={{
                      left: '0rem',
                      position: 'absolute',
                      transform: 'rotate(-4deg)',
                      borderRadius: '2rem',
                    }}
                  />
                  <AtiraImage
                    src={Images.FeaturesNerve2}
                    width="50%"
                    style={{
                      transform: 'rotate(4deg)',
                      borderRadius: '2rem',
                    }}
                  />
                  <HeroImageRightCircle>
                    <AtiraImage src={Images.FeaturesSecondCircle} />
                  </HeroImageRightCircle>
                </HeroImageWrapper>
              </Col>
            </Row>
          </Section>

          <Section paddingTop="xxxl" paddingBottom="xxxl">
            <Row justify={'space-around'}>
              <Col
                xs={0}
                sm={0}
                md={0}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 9, order: 1 }}
              >
                <Flex paddingTop="xl">
                  <AtiraImage src={Images.FeaturesNerve3} width="100%" />
                </Flex>
              </Col>

              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                lg={{ span: 11, order: 2 }}
                xl={{ span: 11, order: 2 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Flex flexDirection="column" gap="m">
                  <Text fontSize="xxl" fontWeight={'bolder'} color="white">
                    {t('front_page.section.1.title')}
                  </Text>
                  <Text fontSize="xl" color="white">
                    {t('front_page.section.1.paragraph')}
                  </Text>

                  <FirstSectionMobileImageWrapper>
                    <AtiraImage src={Images.FeaturesNerve3} width="100%" />
                  </FirstSectionMobileImageWrapper>
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={8}
                      style={{ marginTop: '2rem' }}
                    >
                      <FeatureWrapper>
                        <AtiraImage
                          src={Images.FeaturesSecondSection1}
                          width="45%"
                        />
                        <Text fontSize="xxl" color="white">
                          {t('front_page.section.1.feature.1.title')}
                        </Text>
                        <Text color="white" fontSize="l">
                          {t('front_page.section.1.feature.1.description')}
                        </Text>
                      </FeatureWrapper>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={8}
                      style={{ marginTop: '2rem' }}
                    >
                      <FeatureWrapper>
                        <AtiraImage
                          src={Images.FeaturesSecondSection2}
                          width="45%"
                        />
                        <Text fontSize="xxl" color="white">
                          {t('front_page.section.1.feature.2.title')}
                        </Text>
                        <Text color="white" fontSize="l">
                          {t('front_page.section.1.feature.1.description')}
                        </Text>
                      </FeatureWrapper>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={8}
                      style={{ marginTop: '2rem' }}
                    >
                      <FeatureWrapper>
                        <AtiraImage
                          src={Images.FeaturesSecondSection3}
                          width="45%"
                        />
                        <Text fontSize="xxl" color="white">
                          {t('front_page.section.1.feature.3.title')}
                        </Text>
                        <Text color="white" fontSize="l">
                          {t('front_page.section.1.feature.1.description')}
                        </Text>
                      </FeatureWrapper>
                    </Col>
                  </Row>
                </Flex>
              </Col>
            </Row>
          </Section>
        </StyledContent>

        <StyledFooter>
          <Row justify={'space-evenly'}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Flex flexDirection="column" alignItems="center" gap="m">
                <AtiraImage
                  src={Images.AtiraSpaceLogo}
                  style={{ maxWidth: '17rem' }}
                />
                <Flex width={'90%'} justifyContent="center">
                  <Text
                    align="center"
                    color="white"
                    wordBreak="break-word"
                    fontSize="l"
                  >
                    {t('app.description')}
                  </Text>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text align="center" color="white">
                {t('app.all_rights_reserved')}
              </Text>
            </Col>
          </Row>
        </StyledFooter>
      </Layout>

      <StyledDrawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        placement="left"
        style={drawerStyle}
      >
        <Flex flexDirection="column" gap="m">
          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="white" size="xs" />

            <DrawerLink to={''}>{t('common.how_to')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="white" size="xs" />

            <DrawerLink to={''}>{t('common.templates')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="white" size="xs" />

            <DrawerLink to={''}>{t('common.pricing')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="white" size="xs" />

            <DrawerLink to={''}>{t('common.help')}</DrawerLink>
          </Flex>
        </Flex>
      </StyledDrawer>
    </Flex>
  );
};
