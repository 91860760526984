import { Flex } from './Flex';

type PageHeaderProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  leftComponent,
  rightComponent,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Flex gap="m">{leftComponent}</Flex>
      <Flex justifyContent="end">{rightComponent}</Flex>
    </Flex>
  );
};
