import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type UploadListType = 'text' | 'picture' | 'picture-card' | 'picture-circle';

const StyledUpload = styled(Upload)`
  .ant-upload-select {
    display: block;
  }
`;

const uploadProps: UploadProps = {
  name: 'file',
  defaultFileList: [],
  beforeUpload: (file) => false,
  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

type ImageUplaodProps = {
  onChange: (file: UploadFile) => void;
  defaultFiles?: UploadFile[];
  listType?: UploadListType;
  button?: React.ReactNode;
  showUploadList?: boolean;
} & UploadProps;

export const ImageUpload: React.FC<ImageUplaodProps> = ({
  onChange,
  defaultFiles,
  listType,
  button,
  showUploadList = true,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledUpload
      showUploadList={showUploadList}
      listType={listType}
      {...uploadProps}
      maxCount={1}
      defaultFileList={defaultFiles}
      onChange={(info) => {
        uploadProps.onChange!(info);
        if (info.file.status !== 'removed') {
          onChange(info.file);
        }
      }}
      {...props}
    >
      {button || (
        <Button icon={<UploadOutlined />}>{t('common.click_upload')}</Button>
      )}
    </StyledUpload>
  );
};
