import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '../../components/Flex';
import { QRActions } from '../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { UserQRs } from './UserQRs';

export const QRRoute: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getUserQRs = async () => {
      try {
        await dispatch(
          QRActions.getUserQRs({ userId: loggedInUserId }),
        ).unwrap();
      } catch (e: any) {
        console.log(e);
        AtiraToast.apiError(e);
      }
    };

    getUserQRs();
  }, [dispatch, loggedInUserId]);

  return (
    <Flex flexDirection="column">
      <UserQRs />
    </Flex>
  );
};
