import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraError } from '../../components/AtiraError';
import { Flex } from '../../components/Flex';
import { QRSliceSelectors } from '../../redux/QR/qr.selector';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { QRCode } from './components/QRCode';

export const UserQRs: React.FC = () => {
  const { t } = useTranslation();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const userQRs = useAppSelector((s) =>
    QRSliceSelectors.selectAllQRs(s, loggedInUserId),
  )!;

  const renderQRs = useCallback(
    () =>
      userQRs?.map((qr) => (
        <QRCode key={qr.fields.data} qr={qr} showDeleteButton={true} />
      )),
    [userQRs],
  );

  if (!userQRs.length) {
    return <AtiraError errorMessage={t('qr.no_qrs')} />;
  }

  return (
    <Flex
      width={'100%'}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="flex-start"
      gap="xxxl"
    >
      {renderQRs()}
    </Flex>
  );
};
