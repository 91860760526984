import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const atiraLinkState = (state: RootState) => state.atiraLink;

const selectUserAtiraLink = createSelector(
  atiraLinkState,
  (state) => state.atiraLink,
);

export const atiraLinkSliceSelectors = { selectUserAtiraLink };
