import { faFaceFrown } from '@fortawesome/free-solid-svg-icons/faFaceFrown';

import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';

interface AtiraErrorProps {
  errorMessage: string;
  showImage?: boolean;
}

export const AtiraError: React.FC<AtiraErrorProps> = ({
  errorMessage,
  showImage = true,
}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        height={'100%'}
        width={'100%'}
        gap="xxl"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="xl" align="center">
          {errorMessage}
        </Text>

        {showImage ? <AtiraIcon icon={faFaceFrown} size="6x" /> : null}
      </Flex>
    </Flex>
  );
};
