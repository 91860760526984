import { Image } from 'antd';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkThemes } from '../../../model/atira-link/types/AtiraLinkThemes.enum';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const iterableThemes: Record<AtiraLinkThemes, Record<string, string>> = {
  [AtiraLinkThemes.BASIC]: {
    mini: Images.Themes.BasicMini,
    full: Images.Themes.BasicFull,
    side: Images.Themes.BasicSide,
  },
  [AtiraLinkThemes.DREAM]: {
    mini: Images.Themes.DreamMini,
    full: Images.Themes.DreamFull,
    side: Images.Themes.DreamSide,
  },
  [AtiraLinkThemes.FEMOS]: {
    mini: Images.Themes.FemosMini,
    full: Images.Themes.FemosFull,
    side: Images.Themes.FemosSide,
  },
  [AtiraLinkThemes.JILLY]: {
    mini: Images.Themes.JillyMini,
    full: Images.Themes.JillyFull,
    side: Images.Themes.JillySide,
  },
  [AtiraLinkThemes.DEMONA]: {
    mini: Images.Themes.DemonaMini,
    full: Images.Themes.DemonaFull,
    side: Images.Themes.DemonaSide,
  },
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  gap: ${Spacing.m};
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const CustomTemplate = styled(Flex)`
  background-color: ${({ theme }) => theme.lightgray};
  border-radius: ${Rounded.xl};
  width: 10rem;
  height: 17rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledButton = styled(Button)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : theme.gray};
  color: ${({ theme, selected }) => (selected ? theme.white : theme.primary)};
`;

export const AtiraLinkTemplatesTab: React.FC = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreateAtiraLinkDto>();
  const theme = useWatch({ name: 'theme' });

  return (
    <Flex width={'100%'} gap="l">
      <SectionWrapper>
        <Text fontWeight="bold" fontSize="xm" color="darkerSub">
          {t('common.templates')}
        </Text>
        <Text color="gray">
          ({t('atira_link.create.templates.template_title')})
        </Text>

        <Section>
          {Object.entries(iterableThemes).map(([key, value]) => (
            <Flex
              flexDirection="column"
              alignItems="center"
              gap="s"
              width="fit-content"
              key={key}
            >
              <CustomTemplate>
                <Image.PreviewGroup>
                  <Image src={value.mini} alt="mini" />
                  <Image
                    style={{ display: 'none' }}
                    src={value.full}
                    alt="full"
                  />
                  <Image
                    style={{ display: 'none' }}
                    src={value.side}
                    alt="side"
                  />
                </Image.PreviewGroup>
              </CustomTemplate>
              <StyledButton
                onClick={() => setValue('theme', key as AtiraLinkThemes)}
                selected={theme === key}
              >
                {key}
                {theme === key ? ' ✓' : ''}
              </StyledButton>
            </Flex>
          ))}
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
