import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/';
import React from 'react';
import { CSSProperties } from 'styled-components';

import { useTheme } from '../ThemeContext';
import { ThemeType } from '../theme/Theme';

interface AtiraIconProps {
  color?: keyof ThemeType;
  icon: IconProp;
  size?: SizeProp;
  cursor?: CSSProperties['cursor'];
}
export const AtiraIcon: React.FC<AtiraIconProps> = ({
  color,
  icon,
  size = '1x',
  cursor,
}) => {
  const { theme } = useTheme();

  return (
    <FontAwesomeIcon
      icon={icon}
      color={theme[color!]}
      size={size}
      cursor={cursor}
    />
  );
};
