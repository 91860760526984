import { Col } from 'antd';
import React from 'react';

interface AtiraColumnProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: React.ReactNode;
}

export const AtiraColumn: React.FC<AtiraColumnProps> = ({
  xs = 24,
  sm = 24,
  md = 8,
  lg = 8,
  xl = 10,
  children,
}) => {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Col>
  );
};
