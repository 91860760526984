import { Layout as LayoutComponent } from 'antd';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Flex } from '../components/Flex';
import { SideMenu } from '../components/SideMenu';
import useWindowDimensions from '../hooks/useWindowDimensions';
import i18n, { AppLangs } from '../i18n';
import { appSliceSelectors } from '../redux/app/app.selector';
import { appActions } from '../redux/app/app.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { Breakpoints } from '../theme/Breakpoints';

const OutletWrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.sub};
`;

const StyledSiderLayout = styled(LayoutComponent.Sider)`
  height: 100vh;
  background-color: ${({ theme }) => theme.main} !important;

  overflow: hidden;
  .ant-layout-sider-trigger {
    display: none !important;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    .ant-layout-sider-trigger {
      display: block !important;
    }
  }

  .ant-layout-sider-trigger span {
    rotate: ${i18n.language === AppLangs.AR ? '180deg' : '0'};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    .ant-layout-sider-trigger {
      display: block !important;
    }
  }
`;

export const Layout: React.FC = () => {
  const dispatch = useAppDispatch();

  const storedValue = useAppSelector(appSliceSelectors.selectSideMenuCollapsed);

  const [collapsed, setCollapsed] = useState(Boolean(storedValue));

  const { width } = useWindowDimensions();

  const onCollapse = (newCollapsed: boolean) => {
    setCollapsed(newCollapsed);
    dispatch(appActions.setSideMenuCollapsed(newCollapsed));
  };

  useEffect(() => {
    if (width < 992) {
      setCollapsed(true);
    } else {
      setCollapsed(collapsed);
    }
  }, [collapsed, width]);

  return (
    <LayoutComponent style={{ width: '100%', height: '100%' }}>
      <StyledSiderLayout
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <SideMenu collapsed={collapsed} />
      </StyledSiderLayout>

      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </LayoutComponent>
  );
};
