import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { URLAxios } from '../../axios/url/url.axios';
import { AtiraURL } from '../../model/url/Url';
import { DeleteURLDto } from '../../model/url/dto/DeleteURLDto';
import { GetUserShortURLsDto } from '../../model/url/dto/GetUserShortURLsDto';
import { GetUserShortURLsResponseDto } from '../../model/url/dto/GetUserShortURLsResponseDto';
import { ShortenURLDto } from '../../model/url/dto/ShortenURLDto';
import { ShortenURLResponseDto } from '../../model/url/dto/ShortenURLResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { GenericWithReduxEntry } from '../shared/GeneicWithReduxEntry';
import { userActions } from './../user/user.slice';
import { GenericDisableEnableURLDto } from './dto/GenericDisableEnableURLDto';

interface URLReducer {}

const initialState = Object.freeze<URLReducer>({});

const getShortURL = AtiraThunk<ShortenURLResponseDto, ShortenURLDto>(
  `/url/shorten`,
  (dto) => URLAxios.getShortURL(dto),
);

const deleteURL = AtiraThunk<void, DeleteURLDto>(`/url/delete`, (dto) =>
  URLAxios.deleteURL(dto),
);

const urlsAdapter = createEntityAdapter<GenericWithReduxEntry<AtiraURL>>();
const getUserShortURLs = AtiraThunk<
  GetUserShortURLsResponseDto,
  GetUserShortURLsDto
>('/url/get', (dto) => URLAxios.getUseShortURLs(dto));

const disableUserURL = AtiraThunk<void, GenericDisableEnableURLDto>(
  `/url/disable`,
  (dto) => URLAxios.disableUserURL(dto),
);

const enableUserURL = AtiraThunk<void, GenericDisableEnableURLDto>(
  `/url/enable`,
  (dto) => URLAxios.enableUserURL(dto),
);

export const urlAdapterSelectors = urlsAdapter.getSelectors();

const urlSlice = createSlice({
  name: 'url',
  initialState: { ...initialState, urls: urlsAdapter.getInitialState() },
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getShortURL.fulfilled, (state, action) => {
      const { url } = action.payload;
      const userId = action.meta.arg.userId;

      const userURLs = urlAdapterSelectors.selectById(state.urls, userId);

      urlsAdapter.upsertOne(state.urls, {
        id: userId,
        urls: [...(userURLs?.urls || []), url].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        ),
      });
    });

    addCase(getUserShortURLs.fulfilled, (state, action) => {
      urlsAdapter.upsertOne(state.urls, {
        id: action.meta.arg.userId,
        urls: action.payload.data,
      });
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = { ...initialState, urls: urlsAdapter.getInitialState() });
    });
  },
});

export const shortenUrlActions = {
  getShortURL,
  getUserShortURLs,
  deleteURL,
  disableUserURL,
  enableUserURL,
};

export default urlSlice.reducer;
